import { Routes } from '@angular/router';
import { LoginComponent } from '../component/login/login.component';
import { ClientDetailsComponent } from '../component/client-details/client-details.component';
import { AdminComponent } from '../component/admin/admin/admin.component';
import { AuthGuard } from '../guard/auth.guard';
import { RoleGuard } from '../guard/role.guard';

export const routes: Routes =  [
    { path: '', redirectTo: 'login', pathMatch: 'full' },
    { path: 'login', component: LoginComponent },
    { path: 'client-details', component: ClientDetailsComponent, canActivate: [AuthGuard] },
    { path: 'admin', component: AdminComponent, canActivate: [AuthGuard, RoleGuard] },
    { path: '**', redirectTo: 'login' }
  ];
