<form [formGroup]="spaForm" (ngSubmit)="onSubmit()" class="container mt-5" id="form-to-pdf">

    <div class="card shadow-lg p-4">
      <div class="text-center mb-4">
        <h1 class="h3">Amatram Spa</h1>
      </div>
  
      <!-- Client Details Section -->
      <h4 class="mb-3">Client Details</h4>
      <div formGroupName="clientDetails" class="row g-3">
        <div class="col-md-6">
          <label for="name" class="form-label">Name</label>
          <input
            type="text"
            id="name"
            formControlName="name"
            class="form-control"
          />
          <div *ngIf="isFieldInvalid('clientDetails.name')" class="text-danger">
            Name is required.
          </div>
        </div>
  
        <div class="col-md-6">
          <label for="phone" class="form-label">Phone Number</label>
          <input
            type="text"
            id="phone"
            formControlName="phone"
            class="form-control"
          />
          <div *ngIf="isFieldInvalid('clientDetails.phone')" class="text-danger">
            Phone number is required and must be a valid 10-digit number.
          </div>
        </div>
  
        <div class="col-md-6">
          <label for="age" class="form-label">Age</label>
          <input
            type="number"
            id="age"
            formControlName="age"
            class="form-control"
          />
          <div *ngIf="isFieldInvalid('clientDetails.age')" class="text-danger">
            Age is required and must be between 18 and 100.
          </div>
        </div>
  
        <div class="col-md-6">
          <label for="reference" class="form-label">Reference Name</label>
          <input
            type="text"
            id="reference"
            formControlName="referenceName"
            class="form-control"
          />
          <div *ngIf="isFieldInvalid('clientDetails.referenceName')" class="text-danger">
            Reference Name is required.
          </div>
        </div>

        <div class="col-md-6">
            <label for="reference-phone-number" class="form-label">Reference Phone Number</label>
            <input
              type="text"
              id="reference-phone-number"
              formControlName="referencePhoneNumber"
              class="form-control"
            />
            <div *ngIf="isFieldInvalid('clientDetails.referencePhoneNumber')" class="text-danger">
              Phone number is required and must be a valid 10-digit number.
            </div>
          </div>
      </div>
  
      <hr class="my-4">
  
      <!-- Staff Details Section -->
      <h4 class="mb-3">Staff Details</h4>
      <div formGroupName="staffDetails" class="row g-3">
        <div class="col-md-6">
          <label for="staffName" class="form-label">Staff Name</label>
          <input
            type="text"
            id="staffName"
            formControlName="staffName"
            class="form-control"
          />
          <div *ngIf="isFieldInvalid('staffDetails.staffName')" class="text-danger">
            Staff name is required.
          </div>
        </div>
  
        <div class="col-md-6">
          <label for="serviceType" class="form-label">Service Type</label>
          <select
            id="serviceType"
            formControlName="serviceType"
            class="form-select"
          >
            <option value="" disabled selected>Select a service type</option>
            <option *ngFor="let service of services" [value]="service">{{ service }}</option>
          </select>
          <div *ngIf="isFieldInvalid('staffDetails.serviceType')" class="text-danger">
            Service type is required.
          </div>
        </div>

        <div class="col-md-6">
          <label for="serviceCharge" class="form-label">Service Charge</label>
          <input
            type="number"
            id="serviceCharge"
            formControlName="serviceCharge"
            class="form-control"
          />
          <div *ngIf="isFieldInvalid('staffDetails.serviceCharge')" class="text-danger">
            Service Charge is required.
          </div>
        </div>
        
        </div>
  
        <!-- <div class="col-md-6">
          <label for="clientName" class="form-label">Client Name</label>
          <input
            type="text"
            id="clientName"
            formControlName="clientName"
            class="form-control"
          />
          <div *ngIf="isFieldInvalid('staffDetails.clientName')" class="text-danger">
            Client name is required.
          </div>
        </div> -->
      <!-- </div> -->
  
      <div class="mt-4 text-center">
        <button type="submit" [disabled]="spaForm.invalid" class="btn btn-primary">Submit</button>
      </div>
    </div>
    <app-loading [isLoading]="isLoading && isLoadingStaff"></app-loading>
  </form>
  