<div class="container">
    <h2>Client Details</h2>
  
    <app-loading [isLoading]="isLoading"></app-loading>
  
    <div *ngIf="clientDetails && clientDetails.length" class="table-responsive">
      <table class="client-table">
        <thead>
          <tr>
            <th>Client Name</th>
            <th>Phone Number</th>
            <th>Reference Name</th>
            <th>Reference Phone Number</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let client of clientDetails">
            <td>{{ client.name }}</td>
            <td>{{ client.phoneNumber }}</td>
            <td>{{ client.reference.name }}</td>
            <td>{{ client.reference.phoneNumber }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  
    <div *ngIf="!clientDetails || !clientDetails.length" class="no-data-message">
      <p>No Client Exist In System</p>
    </div>
  </div>
  