import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';
import { ClientDetails } from '../app/model/client-details';
import { AuthService } from './auth-service';

@Injectable({
  providedIn: 'root'
})
export class ClientService {

  constructor(private http : HttpClient) { }

  authenticateUser(username: string, password: string) : Observable<any> {
    const requestBody = {
        username: username,
        password: password
    }
    return this.http.post<any>(`${environment.apiUrl}/auth/login`,requestBody,{
      headers: { 'client-id': environment.clientId}
    });
  }

  getClientDetails(jwt:string): Observable<ClientDetails[]> {
    return this.http.get<ClientDetails[]>(`${environment.apiUrl}/admin/client`,{
      headers: { 
        'Authorization':'Bearer ' + jwt,
        'client-id': environment.clientId}
    });
  }
}
