<div class="container mt-5">
    <div class="card p-5 shadow">
      <h2 class="text-center mb-4">Login</h2>
  
      <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
        <div class="mb-3">
          <label for="username" class="form-label">Username</label>
          <input
            type="text"
            id="username"
            formControlName="username"
            class="form-control"
            required
          />
          <div *ngIf="loginForm.get('username')?.invalid && loginForm.get('username')?.touched" class="text-danger">
            Username is required.
          </div>
        </div>
  
        <div class="mb-3">
          <label for="password" class="form-label">Password</label>
          <input
            type="password"
            id="password"
            formControlName="password"
            class="form-control"
            required
          />
          <div *ngIf="loginForm.get('password')?.invalid && loginForm.get('password')?.touched" class="text-danger">
            Password is required.
          </div>
        </div>
        <div class="text-center">
          <button type="submit" class="btn btn-primary">Login</button>
        </div>
  
        <div *ngIf="loginError" class="text-danger text-center mt-3">
          {{ loginError }}
        </div>

        <app-loading [isLoading]="isLoading"></app-loading>
      </form>
    </div>
  </div>
  