import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { environment } from '../../environments/environment';
import { ClientDetails } from '../../app/model/client-details';
import { ReferenceDetails } from '../../app/model/reference-details';
import { AuthService } from '../../service/auth-service';
import { LoadingComponent } from '../loading/loading.component';
import { StaffDetails } from '../../app/model/staff-details';

import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
@Component({
  selector: 'app-client-details',
  templateUrl: './client-details.component.html',
  styleUrls: ['./client-details.component.css'],
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule, LoadingComponent],
})
export class ClientDetailsComponent implements OnInit {
  spaForm!: FormGroup;
  isLoading: boolean = false;
  isLoadingStaff : boolean = false;
  services: string[] = [
    'Body By Natural Oil',
    'Body By Hot Stone',
    'Body By Herbal Bags',
    'Body Lotion Cream',
    'Body Lotion Cream And Hot Stone',
    'SPA Body Lotion Candle',
    'Body By Bamboo & Lotion Cream',
    'Body By Ginger And Hot Stone',
    'Body Thai',
    'Pregnant Women Spa - Lotion Cream'
  ];
  constructor(private fb: FormBuilder, private http: HttpClient,
    private authService : AuthService
  ) {}

  ngOnInit(): void {
    // Initialize the ReactiveForm
    this.spaForm = this.fb.group({
      clientDetails: this.fb.group({
        name: ['', Validators.required],
        phone: ['', [Validators.required, Validators.pattern('^([0-9]{10})$')]],
        age: ['', [Validators.required, Validators.min(18), Validators.max(100)]],
        referenceName: ['', Validators.required],
        referencePhoneNumber: ['', [Validators.required, Validators.pattern('^([0-9]{10})$')]]
      }),
      staffDetails: this.fb.group({
        staffName: ['', Validators.required],
        serviceType: ['', Validators.required],
        serviceCharge: ['', Validators.required]
      })
    });
  }

  onSubmit(): void {
    if (this.spaForm.valid) {
      this.isLoading = true;
      this.isLoadingStaff = true;
      const formData = this.spaForm.value;
      const clientFormData = formData.clientDetails;
      const staffFormData = formData.staffDetails;
      const referenceDetails = new ReferenceDetails();
      referenceDetails.name = clientFormData.referenceName;
      referenceDetails.phoneNumber = clientFormData.referencePhoneNumber;
      const clientDetails  = new ClientDetails();
      clientDetails.name = clientFormData.name;
      clientDetails.phoneNumber = clientFormData.phone;
      clientDetails.age = clientFormData.age;
      clientDetails.reference = referenceDetails;
      const staffDetails = new StaffDetails();
      staffDetails.name = staffFormData.staffName;
      staffDetails.serviceCharge = staffFormData.serviceCharge;
      staffDetails.clientPhoneNumber = clientFormData.phone;

      this.http.post(`${environment.apiUrl}/user/staff`, staffDetails,
        {
          headers: { 'client-id': environment.clientId,
            'Authorization':`Bearer ${this.authService.getJwt()}`
          }
        }
      ).subscribe({
        next: (response) => {
          this.isLoadingStaff = false;
          
        },
        error: (error) => {
          this.isLoading = false;
          console.error('Error Saving Details:', error);
          alert('Error Saving Details:. Please try again.');
        }
      });

      this.http.post(`${environment.apiUrl}/user/client`, clientDetails,
        {
          headers: { 'client-id': environment.clientId,
            'Authorization':`Bearer ${this.authService.getJwt()}`
          }
        }
      ).subscribe({
        next: (response) => {
          this.isLoading = false;
          // this.spaForm.patchValue({
          //   staffDetails: {
          //     serviceCharge: ''  // Set serviceCharge to blank
          //   }
          // });
          
          this.generatePDF(clientDetails.name+'_'+clientDetails.phoneNumber);
          
        },
        error: (error) => {
          this.isLoading = false;
          
          if(error.error.errorCode[0].code === 409){
            // this.spaForm.patchValue({
            //   staffDetails: {
            //     serviceCharge: ''
            //   }
            // });
            this.generatePDF(clientDetails.name+'_'+clientDetails.phoneNumber);
          }
          else{
          console.error('Error Saving Details:', error);
          alert('Error Saving Details:. Please try again.');
          }
        }
      });

    } else {
      console.log('Form is invalid');
    }

  }
  isFieldInvalid(controlName: string): boolean {
    const control = this.spaForm.get(controlName);
    return control ? control.invalid && (control.dirty || control.touched) : false;
  }

   // Function to generate the PDF
   generatePDF(fileName: string): void {
   const tc1 : string = 'Amatram Wellness and SPA,';
   const tc2 : string = 'No Illegal or immoral activities are promoted here.';
   const tc3 : string = 'Owners shall not be responsible if customer therapists are found involved in any immoral activities in the SPA.';
   const tc4 : string = 'Payment once made shall not be refundable under any circumstances.';
   const tc5 : string = 'Correct mobile number mandatory for customer entry management.';

      const formData = this.spaForm.value;
      const clientFormData = formData.clientDetails;
      const staffFormData = formData.staffDetails;
    const doc = new jsPDF();

    // Adding title
    doc.setFontSize(18);
    doc.text('Amatram Spa - Client Details', 10, 10);

    // Adding Client Details
    const clientDetails = this.spaForm.get('clientDetails')?.value;
    doc.setFontSize(14);
    doc.text('Client Details:', 10, 30);
    doc.setFontSize(12);
    doc.text(`Name: ${clientDetails.name}`, 20, 40);
    doc.text(`Phone: ${clientDetails.phone}`, 20, 50);
    doc.text(`Reference Name: ${clientFormData.referenceName}`, 20,60);
    doc.text(`Reference Phone Number: ${clientFormData.referencePhoneNumber}`, 20,70);

    // Adding Staff Details
    doc.setFontSize(12);
    doc.text('------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------', 10, 80);

  
    doc.setFontSize(14);
    doc.text('Staff Details:', 10, 90);


    doc.setFontSize(12);
    doc.text(`Staff Name: ${staffFormData.staffName}`, 20, 100);
    doc.text(`Service Type: ${staffFormData.serviceType}`, 20, 110);
    //doc.text(`Service Charge: ${staffFormData.serviceCharge}`, 20, 120);

    doc.setFontSize(8);
    doc.text('Terms and Conditions:',10,120);
    doc.text(tc1,10,125);
    doc.text(tc2,10,129);
    doc.text(tc3,10,133);
    doc.text(tc4,10,137);
    doc.text(tc5,10,141);


    doc.setFontSize(12);
    doc.text('--------------------',150,150)
    doc.text('Customer Sign.',150,153);
    



    // Save the PDF
    doc.save(fileName+'.pdf');
    this.spaForm.reset();
  }
}
