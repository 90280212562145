import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../service/auth-service';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { LoadingComponent } from "../loading/loading.component"; // Import RouterModule

@Component({
  selector: 'app-login',
  standalone: true,
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  imports: [CommonModule, ReactiveFormsModule, LoadingComponent],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  loginError: string = '';
  isLoading: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private router: Router
  ) {
    // Initialize the form group
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      role: ['user'] // Default role
    });
  }

  ngOnInit() {}
  onSubmit() {
    if (this.loginForm.valid) {
      this.isLoading = true;
      const { username, password } = this.loginForm.value;
  
      this.authService.login(username, password).subscribe(
        (isAuthenticated: boolean) => {
          if (isAuthenticated) {
            // Navigate based on role
            const role = this.authService.getRole();
            this.isLoading = false;
            if (role === 'ADMIN') {
              this.router.navigate(['/admin']);
            } else {
              this.router.navigate(['/client-details']);
            }
          } else {
            this.isLoading = false;
            this.loginError = 'Invalid credentials. Please try again!';
          }
        },
        (error) => {
          this.isLoading = false;
          console.error('Login error:', error);
          this.loginError = 'An error occurred. Please try again!';
        }
      );
    }
  }
}
