import { Injectable } from '@angular/core';
import { ClientService } from './client.service';
import { TokenService } from './token.service';
import { catchError, map, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private clientService : ClientService,
    private tokenService : TokenService
  ){}
  private isAuthenticated = false;
  private userRole: string | null = '';
  private jwt : string = '';
  
  login(username: string, password: string): Observable<boolean> {
    // Returning an Observable that emits boolean values
    return this.clientService.authenticateUser(username, password).pipe(
      map((data: any) => {
        if (data.jwt) {
          this.userRole = this.tokenService.getRoleFromTokenManual(data.jwt); // Extract the role from the token
          this.jwt = data.jwt;
          this.isAuthenticated = true;
          return true;  // Emit true for successful login
        } else {
          this.isAuthenticated = false;
          return false;  // Emit false for failed login
        }
      }),
      catchError((err) => {
        console.error('Error occurred:', err);
        this.isAuthenticated = false;
        return of(false);  // Emit false when an error occurs
      })
    );
  }
  

  logout() {
    this.isAuthenticated = false;
    this.userRole = '';
  }

  getRole(): string | null {
    return this.userRole;
  }

  isLoggedIn(): boolean {
    return this.isAuthenticated;
  }
  getJwt(): string{
    return this.jwt;
  }
}
