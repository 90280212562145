import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  constructor() { }

  decodeTokenManually(token: string): any {
    const payload = token.split('.')[1];  // Get the payload part of the token
    const decodedPayload = atob(payload);  // Decode base64
    return JSON.parse(decodedPayload);     // Parse JSON
  }
  
  // Function to get role from the manually decoded token
  getRoleFromTokenManual(token: string): string | null {
    const decodedToken = this.decodeTokenManually(token);
    if (decodedToken && decodedToken.role) {
      return decodedToken.role;
    } else {
      console.warn('Role not found in token');
      return null;
    }
  }
}
