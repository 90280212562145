import { Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ClientService } from '../../../service/client.service';
import { CommonModule } from '@angular/common';
import { LoadingComponent } from "../../loading/loading.component";
import { AuthService } from '../../../service/auth-service';
import { ClientDetails } from '../../../app/model/client-details';

@Component({
  selector: 'app-admin',
  standalone: true,
  imports: [CommonModule, LoadingComponent],
  templateUrl: './admin.component.html',
  styleUrl: './admin.component.css'
})
export class AdminComponent implements OnInit {
  clientDetails!: ClientDetails[];  // This will hold the client data
  isLoading: boolean = false;

  constructor(private clientService: ClientService,private authService : AuthService) {}

  ngOnInit(): void {
    // Fetch client details when the component is initialized
    this.fetchClientDetails();
  }

  fetchClientDetails(): void {
    this.isLoading = true;
    this.clientService.getClientDetails(this.authService.getJwt()).subscribe(
      (data) => {
        this.clientDetails = data;
        this.isLoading = false;
      },
      (error) => {
        this.isLoading  = false;
        console.error('Error fetching client details', error);
      }
    );
  }
}
